import { PaperClipIcon } from '@heroicons/react/20/solid'

export default function NewReportList({ reports }) {
  return (
    <>
      {reports.length > 0 &&
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Reports</dt>
        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
          {reports.map((report) => (
            <li key={report.id} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
              <div className="flex w-0 flex-1 items-center">
                <a onClick={() => {
                  navigator.clipboard.writeText(`https://file.propertycredentials.com/v1/upload?fileId=${report.fileId}`);
                }}>
                  <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 active:text-blue-600 text-gray-400" />
                </a>
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                  <span className="truncate font-medium">{report.category}</span>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href={"https://file.propertycredentials.com/v1/upload?fileId=" + report.fileId} target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500">
                  View
                </a>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a
                  target="_blank"
                  className="font-medium text-red-500 hover:text-red-600"
                  onClick={() => {
                    try {
                      fetch(`https://api.propertycredentials.com//v1/reports?id=${report.id}&please_delete=true`).then((res) => res.json()).then((result) => {
                        if(result.status === 200) {
                          window.location.href = `https://propertycredentials.com/reports?home_id=${report.home}&upload_success=true`;
                        }
                      });
                    } catch(err) {
                      console.error(err);
                    }
                  }}>
                  Delete
                </a>
              </div>
            </li>
          ))}
          </ul>
        </dd>
      </div>
      }
    </>
  )
}
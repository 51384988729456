import { useState } from "react";
import NewHouseSlideOver from "./new-house-slideover";

export default function SellerDashboardStarter() {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={() => setOpen(true)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mx-auto h-12 w-12 text-gray-400" viewBox="0 0 16 16">
        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
      </svg>
      <span className="mt-2 block text-sm font-semibold text-gray-900">You don't currently have any homes, Click here to add a new home</span>
      <NewHouseSlideOver isOpen={isOpen} setOpen={setOpen} />
    </button>
  )
}

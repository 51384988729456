import HomeDetails from "./home-details";
import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

const API_KEY = "AIzaSyDuYcdBhv4rpH3eTX-QTbmZMXBVZnJAdCU";

export default function Feed({ homes }) { 
  const [isOpen, setOpen] = useState(Array(homes.length).fill(false));
  const [imgUrls, setImgUrls] = useState([]);

  const toggleModal = (index) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setOpen(updatedIsOpen);
  }

  const get_house_image = async (address) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`);
    const json = await response.json();
    const { lat, lng } = json.results[0].geometry.location;
    const url = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&key=${API_KEY}`;
    return url;
  };

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        homes.map(async (home) => {
          const address = `${home.street_address}, ${home.city} ${home.state}`;
          const url = await get_house_image(address);
          return url;
        })
      );
      setImgUrls(urls);
    };

    fetchImageUrls();
  }, [homes]);

  return (
    <>
      <ul id="home_feed" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {homes.map((home, index) => (
          <>
            <li key={index} className="relative">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={imgUrls[index]} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" onClick={() => toggleModal(index)} className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {home.street_address}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{home.street_address}, {home.city}, {home.state}, {home.zip}</p>
            </li>
            <HomeDetails img={imgUrls[index]} isOpen={isOpen[index]} setOpen={(isOpen) => toggleModal(index)} home={home} />
          </>
        ))}
      </ul>
    </>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import NewReportList from './new-report-list'
import ReportForm from './report-form'

export default function HomeDetails({ home, isOpen, setOpen }) {
  const [home_reports, set_home_reports] = useState([]);
  useEffect(() => {
    fetch(`https://api.propertycredentials.com/v1/homes?id=${home.id}`)
      .then((res) => res.json())
      .then((json) => {
        if(json.home !== undefined) {
          set_home_reports(json.home.reports);
        }
      });
  }, []);

  const delete_home = () => {
    try {
      fetch(`https://api.propertycredentials.com/v1/homes?id=${home.id}&please_delete=true`).then((res) => res.json()).then((result) => {
        if(result.status === 200) {
          window.location.href = 'https://propertycredentials.com/reports';
        }
      });
    } catch(err) {
      console.error(err);
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {home.street_address}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              {home.city}, {home.state} {home.zip}
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                          <NewReportList reports={home_reports} />
                        </div>

                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                          <div className="mx-auto max-w-3xl">
                            <ReportForm home={home} />
                          </div>
                        </div>
                      </div>


                    </div>
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          onClick={delete_home}
                          type='button'
                          className="inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Delete Home
                        </button>
                        <button
                          type="button"
                          id="share-button"
                          onClick={() => {
                            navigator.clipboard.writeText(`https://propertycredentials.com/home/${home.id}`);
                            document.getElementById('share-button').innerHTML = "Copied!"
                            setTimeout(() => {
                              document.getElementById('share-button').innerHTML = "Share"
                            }, 1500);
                          }}
                          className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Share
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

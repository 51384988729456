import { useState } from "react";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      await fetch(`https://api.propertycredentials.com//v1/users?id=${email}`).then((response) => response.json()).then(async(response) => {
        if(response.status === 200 && (response.user !== undefined || response.user !== null)) {
          document.getElementById('fail_auth').innerText = "A user with that email address already exists";
        } else {
          if(response.status === 404) {
            const formdata = new FormData();
            formdata.append("id", email);
            formdata.append("email", email);
            formdata.append("password", password);

            const requestOptions = {
              method: "POST",
              body: formdata,
            };

            fetch("https://api.propertycredentials.com//v1/users", requestOptions)
              .then((response) => response.json())
              .then((result) => {
                if(result.status === 200) {
                  let user = result.user;
                  delete user.password;

                  localStorage.setItem('user', JSON.stringify(user));
                  window.location.href = "/";
                }
              }).catch((error) => console.error(error));
          }
        }
      });
    } catch(err) {
      console.error(`error_sign_in`, err);
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Register your new <br /><span className="text-blue-500 font-bold text-3xl">Property Credentials</span><br />account
            </h2>
            <p id="fail_auth" className="text-center text-red-600"></p>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm leading-6">
                <a href="/signin" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Already a user?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import Error404 from './routes/error';
import SignIn from './routes/sign-in';
import Register from './routes/register';
import SellerDashboard, { loader as sellerDashboardLoader } from './routes/dashboard/seller';
import SellerHome, { loader as sellerhomeLoader } from './routes/dashboard/home';
import Home from './routes/home';
import Hero from './routes/hero';

export async function signoutLoader() {
  localStorage.removeItem("user");
  window.location.href = "/";
}

export async function is_signed_in() {
  if(!(localStorage.getItem("user"))) {
    window.location.href = "/signin";
  }
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <SellerHome />,
    loader: sellerhomeLoader,
    errorElement: <Error404 />
  },
  {
    path: "/welcome",
    element: <Hero />,
    errorElement: <Error404 />
  },
  {
    path: "/reports",
    element: <SellerDashboard />,
    loader: sellerDashboardLoader,
    errorElement: <Error404 />
    },
  {
    path: "/signin",
    element: <SignIn />,
    errorElement: <Error404 />
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <Error404 />
  },
  {
    path: "/signout",
    loader: signoutLoader,
    errorElement: <Error404 />
  },
  {
    path: "/home/:home_id",
    element: <Home />,
    errorElement: <Error404 />
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();

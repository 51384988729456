import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Footer from '../comp/Footer'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function Hero() {
  return (
    <>
      <div className="relative overflow-hidden bg-gray-50 pb-20">
        <div aria-hidden="true" className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full">
          <div className="relative mx-auto h-full max-w-7xl">
            <svg
              fill="none"
              width={404}
              height={784}
              viewBox="0 0 404 784"
              className="absolute right-full translate-x-1/4 translate-y-1/4 transform lg:translate-x-1/2"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)" width={404} height={784} />
            </svg>
            <svg
              fill="none"
              width={404}
              height={784}
              viewBox="0 0 404 784"
              className="absolute left-full -translate-x-1/4 -translate-y-3/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" width={404} height={784} />
            </svg>
          </div>
        </div>

        <div className="relative pb-16 pt-6 sm:pb-24">

          <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block text-green-600 xl:inline">Property Credentials</span>
              </h1>
              <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
                The report repository for your easy home buying experience
              </p>
              <div className="mx-auto mt-5 max-w-md sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <a
                    href="/register"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 md:px-10 md:py-4 md:text-lg"
                  >
                    Register
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:ml-3 sm:mt-0">
                  <a
                    href="/signin"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-green-600 hover:bg-gray-50 md:px-10 md:py-4 md:text-lg"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

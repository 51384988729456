import { useLocation, useParams } from "react-router-dom"
import Footer from "../comp/Footer";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from "react";

export default function Home() {
  const { home_id } = useParams();
  const [home, set_home] = useState();

  useEffect(() => {
    fetch(`https://api.propertycredentials.com/v1/homes?id=${home_id}`)
    .then((res) => res.json())
    .then((json) => {
      set_home(json.home);
    });
  }, []);

  return (
    <>
      <div className="bg-gray-100 pt-20 pb-20">
        {home !== undefined &&
          <div className="bg-gray-100 pt-5 pb-5">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-3xl">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="px-4 py-5 sm:p-6">
                    <div>

                      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                          <div className="ml-4 mt-2">
                            <h1 className="text-base font-semibold leading-7 text-gray-900">{home.street_address}</h1>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{home.city}, {home.state} {home.zip}</p>
                          </div>
                          <div className="ml-4 mt-2 flex-shrink-0">
                            <button
                              type="button"
                              id="share-button"
                              onClick={() => {
                                navigator.clipboard.writeText(`https://propertycredentials.com/home/${home_id}`);
                                document.getElementById('share-button').innerHTML = "Copied!"
                                setTimeout(() => {
                                  document.getElementById('share-button').innerHTML = "Share"
                                }, 1500);
                              }}
                              className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Share
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <dl className="divide-y divide-gray-100">      
                          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Reports</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                {home.reports.map((report) => (
                                  <li key={report.id} className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                      <a onClick={() => {
                                        navigator.clipboard.writeText(`https://file.propertycredentials.com/v1/upload?fileId=${report.fileId}`);
                                      }}>
                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 active:text-blue-600 text-gray-400" />
                                      </a>
                                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">{report.category}</span>
                                      </div>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                      <a href={"https://file.propertycredentials.com/v1/upload?fileId=" + report.fileId} target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        View
                                      </a>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </dd>
                          </div>
                          
                        </dl>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        { home === undefined && <p>fetching data...</p>}
      </div>
    </>
  )

}
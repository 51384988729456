import { useEffect, useState } from "react";
import { PlusIcon } from '@heroicons/react/20/solid'
import NewHouseSlideOver from "./new-house-slideover";
import HomeDetails from "./home-details";

const API_KEY = "AIzaSyDuYcdBhv4rpH3eTX-QTbmZMXBVZnJAdCU";

export default function SellerHomeContainer({ homes, home_id, upload_success }) {
  const [isOpen, setOpen] = useState(false);
  const [isDetailOpen, setDetailOpen] = useState(Array(homes.length).fill(false));
  const [imgUrls, setImgUrls] = useState([]);

  const toggleModal = (index) => {
    const updatedIsOpen = [...isDetailOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setDetailOpen(updatedIsOpen);
  }

  const get_house_image = async (address) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`);
    const json = await response.json();
    const { lat, lng } = json.results[0].geometry.location;
    const url = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&key=${API_KEY}`;
    return url;
  };

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        homes.map(async (home) => {
          const address = `${home.street_address}, ${home.city} ${home.state}`;
          const url = await get_house_image(address);
          return url;
        })
      );
      setImgUrls(urls);
    };

    fetchImageUrls();
  }, [homes]);

  useEffect(() => {
    if(upload_success === "true" && home_id !== undefined) {
      const index = homes.findIndex((home) => home.id === home_id);
      const updatedIsOpen = [...isDetailOpen];
      updatedIsOpen[index] = !updatedIsOpen[index];
      setDetailOpen(updatedIsOpen);
    }
  }, [upload_success, home_id]);

  return (
    <>
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Click a tile to view or edit</h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
            New Home
          </button>
        </div>
      </div>

      <ul className="pt-5 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {homes.map((home, index) => (
          <>
            <li className="relative">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={imgUrls[index]} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" onClick={() => toggleModal(index)} className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {home.street_address}, {home.city}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{home.street_address}, {home.city}, {home.state}, {home.zip}</p>
            </li>
            <HomeDetails isOpen={isDetailOpen[index]} setOpen={(isDetailOpen) => toggleModal(index)} home={home} />
          </>
        ))}
      </ul>
      
      <NewHouseSlideOver isOpen={isOpen} setOpen={setOpen} />
    </>
  )
}
